export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';

export const setUserDetails = (userData = {}) => {
  return {
    type: SET_USER_DETAILS,
    payload: userData,
  };
};

export const clearUserDetails = () => {
  return {
    type: CLEAR_USER_DETAILS,
  };
};
