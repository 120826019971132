import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import { rootReducer } from './reducers/root-reducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['coachMarks', 'leadingAtCounter'],
};

const initialState = {};

const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storeCreator = () => {
  const composeEnhancers =
    (typeof window != 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );
  const persistor = persistStore(store);

  return { store, persistor };
};

export default storeCreator;
