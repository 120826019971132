import Router from 'next/router';

import {
  DISABLE_COACH_MARKS,
  ENABLE_COACH_MARKS,
  UPDATE_STEP,
} from '../actions/coach-marks-action';

const HOME_ROUTE = '/home';

const initialState = {
  show: false,
  step: 0,
  sourceRoute: HOME_ROUTE,
};

export const coachMarksReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ENABLE_COACH_MARKS: {
      const _sourceRoute = action?.payload?.sourceRoute ?? HOME_ROUTE;

      const newState = {
        show: true,
        step: 1,
        sourceRoute: _sourceRoute,
      };

      return newState;
    }

    case DISABLE_COACH_MARKS: {
      Router.replace(state?.sourceRoute);
      return initialState;
    }

    case UPDATE_STEP: {
      const _step = action?.payload?.step ?? (state?.step ?? 0) + 1;
      return { ...state, step: _step };
    }

    default: {
      return state;
    }
  }
};
