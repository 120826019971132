/* Export action types below this. */
export const SET_LOCATION_VIEW = 'SET_LOCATION_VIEW';
export const SET_LOCATION_GROUP_VIEW = 'SET_LOCATION_GROUP_VIEW';
export const SET_REGION_VIEW = 'SET_REGION_VIEW';
export const CLEAR_VIEW = 'CLEAR_VIEW';
/* Export action types above this. */

/* export action creators here */
export const setLocationGroupViewAction = (locationGroupData = {}) => {
  return {
    type: SET_LOCATION_GROUP_VIEW,
    payload: locationGroupData,
  };
};
export const setLocationView = (locationData = {}) => {
  return {
    type: SET_LOCATION_VIEW,
    payload: locationData,
  };
};

export const setRegionView = (regionData = {}) => {
  return {
    type: SET_REGION_VIEW,
    payload: regionData,
  };
};

export const clearView = () => {
  return {
    type: CLEAR_VIEW,
  };
};
