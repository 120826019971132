import config from 'react-global-configuration';

import DevConfigurations from './env/configs-dev.json';
import ProdConfigurations from './env/configs-prod.json';
import configurations from './configs-common.json';

export const setConfigs = (secrets = {}) => {
  /**
   * @description Add all the variables to be received from the secret file
   * inside the /pages/api/get-config.js file.
   **/
  const environment = secrets?.APP_ENVIRONMENT ?? 'local';
  const cdnBaseUrl = secrets?.CDN_BASE_URL ?? 'https://qa-otm-pnp.elcompanies.com';
  const baseUrl = secrets?.APP_BASE_URL ?? 'https://qa-tr-otm-pnp.elcompanies.com';
  const apiPath = secrets?.APP_API_PATH ?? '/tr-middleware/api/secure';
  const bundleId = secrets?.APP_BUNDLE_ID ?? 'com.elcompanies.tr.otm.pnp.qa';
  const faceIdUrl = secrets?.APP_FACE_ID_URL ?? 'https://qapnp.page.link';
  const truBaseUrl = secrets?.APP_TRU_BASE_URL ?? 'https://qa.elctruniversity.com/';
  const middlewarePath = secrets?.APP_MIDDLEWARE_PATH ?? '/tr-middleware';

  const secretConfigs = {
    environment,
    cdnBaseUrl,
    baseUrl,
    apiPath,
    bundleId,
    faceIdUrl,
    truBaseUrl,
    middlewarePath,
  };

  config.set({ ...configurations, ...secretConfigs }, { freeze: false });

  if (environment === 'production') {
    config?.set(ProdConfigurations, { environment: 'production', freeze: false });
  } else if (environment === 'development') {
    config?.set(DevConfigurations, { environment: 'development', freeze: false });
  }

  config?.setEnvironment(environment);
};
