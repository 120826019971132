import { combineReducers } from 'redux';

import { coachMarksReducer } from './coach-marks-reducer';
import { leadingAtCounterReducer } from './leading-at-counter-reducer';
/**
 * Create reducers as required in reducers folder.
 * Add them to the rootReducer (combineReducer) with suitable state name.
 */
// import {Reducer1} from './Reducer1';
// import {Reducer2} from './Reducer2';
import { onBoardingReducer } from './onboarding-reducer';
import { userDetailsReducer } from './user-details';

export const rootReducer = combineReducers({
  /**
   * Replace state1 with the name you want.
   * Replace Reducer1 with the matching reducer.
   */
  //   state1: Reducer1,
  //   state2: Reducer2,
  onBoarding: onBoardingReducer,
  userDetails: userDetailsReducer,
  coachMarks: coachMarksReducer,
  leadingAtCounter: leadingAtCounterReducer,
});

export default rootReducer;
