import {
  SET_OPEN_DIALOG,
  SET_OPEN_PDF_MODAL,
  SET_SHOULD_SHOW_STORE_VISIT_POPUP,
} from '../actions/leading-at-counter-actions';

const initialState = {
  isLeadingAtCounterDialogOpen: false,
  isLeadingAtCounterPdfModalOpen: false,
  shouldShowStoreVisitPopupAfterFinishPdfStep: false,
};

export const leadingAtCounterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OPEN_DIALOG: {
      return { ...state, isLeadingAtCounterDialogOpen: payload };
    }

    case SET_OPEN_PDF_MODAL: {
      return { ...state, isLeadingAtCounterPdfModalOpen: payload };
    }

    case SET_SHOULD_SHOW_STORE_VISIT_POPUP: {
      return { ...state, shouldShowStoreVisitPopupAfterFinishPdfStep: payload };
    }

    default: {
      return state;
    }
  }
};
