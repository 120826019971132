import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00436D',
      dark: '#002338',
      light: '#EAF2F7',
    },
    secondary: {
      main: '#fff',
    },
    background: {
      default: '#f6f6fa',
    },
    error: {
      main: '#f86868',
    },
    success: {
      main: '#2ebe34',
    },
    text: {
      main: '#212121',
    },
  },
  typography: {
    fontFamily: ['"Helvetica Neue"', 'Roboto', 'Arial'].join(','),
    fontSize: 16,
    fontWeight: 500,
    subtitle1: {
      fontSize: 12,
    },
    // body1: {
    //   fontWeight: 500,
    //   lineHeight: 1.2,
    // },
    button: {
      fontStyle: 'normal',
    },
  },
  // Overrides a common component
  overrides: {
    MuiPaper: {
      elevation1: {
        // boxShadow: 'none',
        // border: '1px solid #f00',
      },
    },
  },
});

export default theme;
