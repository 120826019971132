/* Export action types below this.*/
export const ENABLE_COACH_MARKS = 'ENABLE_COACH_MARKS';
export const DISABLE_COACH_MARKS = 'DISABLE_COACH_MARKS';
export const UPDATE_STEP = 'UPDATE_STEP';
/* Export action types above this.*/

/* export action creators here */
export const enableCoachMarks = (sourceRoute) => {
  return {
    type: ENABLE_COACH_MARKS,
    payload: { sourceRoute },
  };
};

export const disableCoachMarks = () => {
  return {
    type: DISABLE_COACH_MARKS,
  };
};

export const updateStep = (newStep) => {
  return {
    type: UPDATE_STEP,
    payload: { step: newStep },
  };
};
