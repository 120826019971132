import isEmpty from 'lodash/isEmpty';

import { CLEAR_USER_DETAILS, SET_USER_DETAILS } from '../actions/user-details';

const initialState = {};

export const userDetailsReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_USER_DETAILS: {
      const _newData = isEmpty(action?.payload) ? initialState : { ...action?.payload };

      return { ..._newData };
    }

    case CLEAR_USER_DETAILS: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};
