import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import packageJson from '../package.json';

export const DatadogInit = ({ secrets }) => {
  const ENVIRONMENT = secrets?.APP_ENVIRONMENT || 'local';

  useEffect(() => {
    datadogRum.init({
      applicationId: secrets?.APP_DATA_DOG_APPLICATION_ID,
      clientToken: secrets?.APP_DATA_DOG_CLIENT_TOKEN,
      site: secrets?.APP_DATA_DOG_SITE,
      service: secrets?.APP_DATA_DOG_SERVICE_NAME,
      env: `tr-otm-${ENVIRONMENT}`,
      version: packageJson?.version || '0.0.0',
      sessionSampleRate: Number(secrets.APP_DATA_DOG_SESSION_SAMPLE_RATE),
      sessionReplaySampleRate: Number(secrets?.APP_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE),
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }, []);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
};
