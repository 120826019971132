import { getAnalytics, isSupported } from 'firebase/analytics';
import { getApps, initializeApp } from 'firebase/app';

export const initFirebase = (secrets) => {
  if (typeof window !== 'undefined' && !getApps()?.length) {
    const firebaseConfig = {
      apiKey: secrets?.APP_FIREBASE_API_KEY,
      authDomain: secrets?.APP_FIREBASE_AUTH_DOMAIN,
      projectId: secrets?.APP_FIREBASE_PROJECT_ID,
      storageBucket: secrets?.APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: secrets?.APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: secrets?.APP_FIREBASE_APP_ID,
      measurementId: secrets?.APP_FIREBASE_MEASUREMENT_ID,
    };
    const firebaseApp = initializeApp(firebaseConfig);
    isSupported().then((isSupported) => (isSupported ? getAnalytics(firebaseApp) : null));
  }
};
