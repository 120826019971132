import isEmpty from 'lodash/isEmpty';

import {
  CLEAR_VIEW,
  SET_LOCATION_GROUP_VIEW,
  SET_LOCATION_VIEW,
  SET_REGION_VIEW,
} from '../actions/onboarding-actions';

const LOCATION_VIEW_TYPE_LABEL = 'location';
const LOCATION_GROUP_VIEW_TYPE_LABEL = 'locationgroup';
const REGION_VIEW_TYPE_LABEL = 'region';

const initialLocationState = {
  location: {},
  brand: [],
  retailer: {},
  door: {},
};

const initialRegionState = {
  region: {},
  subregion: {},
  country: {},
  brand: [],
  retailer: {},
};
const initialLocationGroupState = {
  locationGroup: {},
  locations: [],
  brands: [],
  retailers: [],
  doors: [],
};

const initialState = {
  viewType: LOCATION_VIEW_TYPE_LABEL,
  locationData: initialLocationState,
  regionData: initialRegionState,
  locationGroupData: initialLocationGroupState,
};

export const onBoardingReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_LOCATION_VIEW: {
      const _newData = isEmpty(action?.payload)
        ? initialLocationState
        : { ...initialLocationState, ...action?.payload };

      const _newState = {
        viewType: LOCATION_VIEW_TYPE_LABEL,
        locationData: _newData,
        locationGroupData: initialLocationGroupState,
        regionData: initialRegionState,
      };

      return { ..._newState };
    }
    case SET_LOCATION_GROUP_VIEW: {
      const _newData = isEmpty(action?.payload)
        ? initialLocationGroupState
        : { ...initialLocationGroupState, ...action?.payload };

      const _newState = {
        viewType: LOCATION_GROUP_VIEW_TYPE_LABEL,
        locationData: initialLocationState,
        locationGroupData: _newData,
        regionData: initialRegionState,
      };

      return { ..._newState };
    }

    case SET_REGION_VIEW: {
      const _newData = isEmpty(action?.payload)
        ? initialRegionState
        : { ...initialRegionState, ...action?.payload };

      const _newState = {
        viewType: REGION_VIEW_TYPE_LABEL,
        regionData: _newData,
        locationData: initialLocationState,
        locationGroupData: initialLocationGroupState,
      };

      return { ..._newState };
    }

    case CLEAR_VIEW: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};
